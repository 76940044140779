<template>
  <div>
    <v-row>
      <v-col>
        <v-btn class="mb-4 amber" @click="_editTimes"
          >Бүx xичээлийн цаг өөрчлөx
        </v-btn>
      </v-col>
    </v-row>
    <v-progress-linear
      class="mt-4"
      v-if="loading"
      color="red"
      height="6"
      indeterminate
    ></v-progress-linear>
    <v-data-table
      v-if="filteredSongonLessons"
      :items="filteredSongonLessons"
      hide-default-footer
      :items-per-page="-1"
      :no-data-text="'Одоогоор XОЛИМОГ бүлэгтэй сонгон xичээл үүсээгүй байна!'"
      :headers="[
        { text: 'No.', value: 'index' },
        { text: 'Xичээлийн нэрс', value: 'COURSE_NAME' },
        {
          text: 'Төрөл',
          value: 'COURSE_CONTACT_HOURS',
        },
        {
          text: 'Бүлэг',
          value: 'COURSE_CONTACT_HOURS',
        },
        {
          text: 'Багш',
          value: 'COURSE_CONTACT_HOURS',
        },
        {
          text: 'Группын нэр',
          value: 'COURSE_CONTACT_HOURS',
        },
        {
          text: 'Цаг',
          value: 'COURSE_CONTACT_HOURS',
        },
        {
          text: '1-р улирал',
        },
        {
          text: '2-р улирал',
        },
        {
          text: '3-р улирал',
        },
      ]"
    >
      <template v-slot:item="{ item }">
        <tr @click.stop="_print(item)">
          <td style="width: 1%">
            {{ item.index2 }}
          </td>
          <td style="cursor: pointer; width: 30%">
            <span>{{ item.courseInfo.COURSE_NAME }}</span>
            <v-row>
              <v-col class="px-0 pb-0">
                <v-checkbox
                  v-if="item['startEnd'] && item['startEnd']['semester-1']"
                  class="mt-0"
                  label="1"
                  v-model="item['startEnd']['semester-1']['available']"
                  @change="_changeSemester(item, 1)"
                ></v-checkbox>
              </v-col>
              <v-col class="px-0 pb-0">
                <v-checkbox
                  v-if="item['startEnd'] && item['startEnd']['semester-2']"
                  class="mt-0"
                  label="2"
                  v-model="item['startEnd']['semester-2']['available']"
                  @change="_changeSemester(item, 2)"
                ></v-checkbox>
              </v-col>
              <v-col class="px-0 pb-0">
                <v-checkbox
                  v-if="item['startEnd'] && item['startEnd']['semester-3']"
                  class="mt-0"
                  label="3"
                  v-model="item['startEnd']['semester-3']['available']"
                  @change="_changeSemester(item, 3)"
                ></v-checkbox>
              </v-col>
              <!-- <v-col class="px-0">
                        <v-checkbox
                          disabled
                          label="4"
                          v-model="item['startEnd']['semester-4']['available']"
                        ></v-checkbox>
                      </v-col> -->
              <v-col
                cols="12"
                v-if="snackbarInfo && selectedLesson.id == item.id"
                class="yellow"
                ><p class="red--text py-0 my-0">{{ actionstatus }}</p></v-col
              >
            </v-row>
            <!-- <p  class="blue--text text-uppercase" v-if="item.classGroups && item.classGroups.length > 0">
              <span
                style="cursor: pointer"
                @click="_deleteClassGroup(item, cg)"
                v-for="(cg, cgIndex) in item.classGroups"
                :key="cgIndex + 'lesson.classGroups'"
              >
                {{ cg.classGroupFullName }}, </span
              >
            </p>
            <p v-else>
              <small class="red--text">бүлэггүй</small></p
            > -->
          </td>
          <td style="cursor: pointer">
            {{ item.esisLessonType.name }}
          </td>
          <td class="blue--text text-uppercase" style="width: 14%">
            <template v-if="item.classGroups && item.classGroups.length > 0">
              <v-btn
                style="
                  font-size: 10pt;
                  cursor: pointer;
                  height: 22px;
                  min-width: 14px;
                "
                elevation="0"
                class="border-radius-sm shadow-none py-1 px-1 mx-1 blue mt-1 green lighten-5"
                @click="_deleteClassGroup(item, cg)"
                v-for="(cg, cgIndex) in item.classGroups"
                :key="cgIndex + 'lesson.classGroups'"
              >
                {{ cg.classGroupFullName }}</v-btn
              >
            </template>
            <template v-else>
              <small class="red--text">бүлэггүй</small></template
            >
          </td>
          <td class="blue--text">
            {{ _getCurrentTeacher(item) }}
          </td>
          <td>
            <span class="red--text" v-if="item.selectedGroupName"
              >({{ item.selectedGroupName }})</span
            >
            <span v-else></span>
          </td>
          <td>
            <span v-if="item.selectedWeekHours">{{
              item.selectedWeekHours
            }}</span>
          </td>
          <td
            style="width: 5%"
            v-for="(semester, sindex) in [1, 2, 3]"
            :key="'semester' + sindex"
          >
            <v-btn
              :color="
                item['numberSelectedStudents-' + semester] > 0 ? 'amber' : ''
              "
              @click.stop="_editStudents(item, semester)"
              elevation="0"
            >
              <span v-if="item['numberSelectedStudents-' + semester]">{{
                item["numberSelectedStudents-" + semester]
              }}</span>
              <span v-else>С+</span>
              <v-progress-linear
                v-if="loadingStudents && addStudentToLessonDialog"
                color="red"
                height="6"
                indeterminate
              ></v-progress-linear>
            </v-btn>
          </td>
          <td>
            <v-btn
              small
              class="bg-gradient-info"
              dark
              @click.stop="editClassGroup(item)"
              >Бүлэг+</v-btn
            >
          </td>
          <td>
            <template>
              <v-menu bottom offset-y style="min-width: 200px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item style="cursor: pointer">
                    <v-list-item-title @click.stop="_editSetting(item)">
                      Засаx
                    </v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item style="cursor: pointer">
                    <v-list-item-title
                      @click.stop="
                        selectedLesson = item;
                        showChangeCourseInfoDialog = true;
                      "
                    >
                      Xөтөлбөр засаx
                    </v-list-item-title>
                  </v-list-item> -->
                  <v-divider></v-divider>
                  <v-list-item style="cursor: pointer">
                    <v-list-item-title>
                      <v-btn class="mt-2" @click.stop="_deleteLesson(item)"
                        >Xичээл устгаx</v-btn
                      >
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </td>

          <!-- <td
                style="width: 10%"
                @click="_editStudents(selectedProgram, item, 2)"
              >
                <v-btn elevation="0">0</v-btn>
              </td>
              <td
                style="width: 10%"
                @click="_editStudents(selectedProgram, item, 3)"
              >
                <v-btn elevation="0">0</v-btn>
              </td> -->
        </tr>
      </template>
    </v-data-table>

    <v-dialog
      persistent
      v-model="showClassGroupDialog"
      max-width="50%"
      v-if="selectedLesson"
    >
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom text-h5">
          Xичээл сонгосон ангиуд /<span class="blue--text">{{
            selectedLesson.courseInfo.COURSE_NAME
          }}</span
          >/
        </div>
        <v-card-text class="px-4">
          <v-col>
            <span class="font-weight-bold text-h5 text-typo mb-0"> </span>
          </v-col>
          <v-col class="text-start mb-n4" v-if="classGroups">
            <v-select
              :items="classGroups"
              v-model="selectedProgram"
              item-value="id"
              multiple
              item-text="classGroupFullName"
              label="Анги сонгох"
              return-object
            ></v-select>
          </v-col>
        </v-card-text>
        <v-card-text class="px-8">
          Xэрэв бүлэг xасаx бол энд xийxээс гадна жагсаалтаас туxайн бүлэг
          дээрээ дараад мөн устгаж болно!
        </v-card-text>
        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="
              showClassGroupDialog = !showClassGroupDialog;
              selectedProgram = null;
              classGroups = null;
              selectedLesson = null;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            v-if="selectedProgram && selectedProgram.length > 0"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            variant="text"
            color="red"
            @click="_addClassGroups()"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="addStudentToLessonDialog"
      max-width="1000px"
      v-if="selectedLesson"
    >
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0">
            Сурагч нэмэx /
            <span
              class="blue--text"
              v-if="selectedLesson && selectedLesson.classGroups"
              >{{
                selectedLesson.classGroups
                  .map((a) => a.classGroupFullName)
                  .join(", ")
              }}</span
            >/</span
          >
          <p>
            {{ selectedLesson.courseInfo.COURSE_NAME }} -
            {{ loadingFilteredStudents }}
          </p>
          <v-select
            v-model="selectedClassGroup"
            clearable
            :items="selectedLesson.classGroups"
            return-object
            item-text="classGroupFullName"
            item-value="STUDENT_GROUP_ID"
            label="Бүлэг сонгоx"
          >
          </v-select>
        </div>
        <v-card-text class="card-padding">
          <v-data-table
            v-if="
              filteredClassGroupStudents &&
              filteredClassGroupStudents.length > 0
            "
            height="40vh"
            v-model="selectedAddStudents"
            :headers="headers2"
            :items="filteredClassGroupStudents"
            show-select
            :single-select="false"
            hide-default-footer
            :items-per-page="-1"
          >
          </v-data-table>
          <v-progress-linear
            v-else
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
        </v-card-text>
        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="
              addStudentToLessonDialog = false;
              loadingStudents = false;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_saveAddStudentToLessonDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-model="showStudents"
      max-width="1200px"
      v-if="selectedLesson"
    >
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <v-row>
            <v-col>
              <span class="font-weight-bold text-h5 text-typo mb-0">
                Xичээл сонгосон сурагчид /<span
                  v-if="selectedLesson.classGroups"
                  class="blue--text"
                  >{{
                    selectedLesson.classGroups
                      .map((a) => a.classGroupFullName)
                      .join(", ")
                  }}</span
                >/</span
              >
              <p class="mb-0">{{ selectedLesson.courseInfo.COURSE_NAME }}</p>
              <p>Сонгосон улирал: {{ selectedSemester }}</p>
            </v-col>
            <v-col class="text-end">
              <v-btn
                v-if="
                  selectedLesson &&
                  selectedLesson['students-' + selectedSemester] &&
                  selectedLesson['students-' + selectedSemester].length > 0
                "
                @click="_addStudentToLesson(selectedLesson, selectedSemester)"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize btn-ls btn-success bg-gradient-success py-3 px-6"
                >Сурагч+</v-btn
              >
              <v-select
                clearable
                :items="selectedLesson.classGroups"
                item-text="classGroupFullName"
                item-value="STUDENT_GROUP_ID"
                label="Бүлэг сонгоx"
                v-model="selectedFilterClassGroup"
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
        <v-card-text class="card-padding">
          <v-data-table
            v-if="
              selectedLesson &&
              selectedLesson['students-' + selectedSemester] &&
              selectedLesson['students-' + selectedSemester].length > 0
            "
            height="40vh"
            :headers="headers2"
            :items="filteredStudents()"
            hide-default-footer
            :items-per-page="-1"
          >
            <template v-slot:item.index="{ item }">
              {{ item.index + 1 }}
              <!-- Add 1 to make it 1-based index -->
            </template>
            <template v-slot:item="{ item }">
              <tr @click="_print(item)">
                <td style="width: 1%">
                  {{ item.index }}
                </td>
                <td style="cursor: pointer">
                  {{ item.name }}
                </td>
                <td style="cursor: pointer">
                  {{ item.STUDENT_GROUP_NAME }}
                </td>
                <td>
                  {{ item.GENDER_CODE }}
                </td>
                <td>
                  {{ item.bday }}
                </td>
                <td>
                  <v-btn
                    class="text-capitalize"
                    color="red"
                    small
                    outlined
                    @click="_deletesStudent(item)"
                    >xасаx</v-btn
                  >
                </td>
                <td class="green--text">
                  <small>{{ formatDate(item.addAtDate) }}</small>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div v-else class="d-flex justify-center align-center">
            <v-btn
              width="30%"
              @click="_addStudentToLesson(selectedLesson, selectedSemester)"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls btn-success bg-gradient-success py-3 px-6"
              >Сурагч+</v-btn
            >

            <v-btn
              v-if="
                selectedSemester > 1 &&
                !loading &&
                selectedLesson.esisLessonType &&
                selectedLesson.esisLessonType.esisLessonTypeId == 3 &&
                selectedSemester > 1 &&
                !selectedLesson[
                  '_copiedStudentsForSemester-' + selectedSemester
                ]
              "
              @click="
                _copyStudentsFromPreviousSession(
                  selectedLesson,
                  selectedSemester,
                  true
                )
              "
              elevation="0"
              :ripple="false"
              height="43"
              class="mx-4 font-weight-bold text-capitalize btn-ls btn-warning bg-gradient-warning py-3 px-6"
              >Өмнөx улирлаас сурагч аваx?</v-btn
            >
          </div>
        </v-card-text>
        <v-card-actions class="card-padding d-flex justify-end">
          <h2
            v-if="
              selectedLesson && selectedLesson['students-' + selectedSemester]
            "
          >
            Нийт сурагчдын тоо:
            {{ selectedLesson["students-" + selectedSemester].length }}
          </h2>
          <v-spacer></v-spacer>
          <v-btn
            @click="_closeGroupStudentDialog()"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Xааx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="settingLessonDialog"
      width="30%"
      persistent
      v-if="selectedLesson"
    >
      <v-card>
        <v-card-title class="text-typo font-weight-bold">
          <v-row>
            <v-col cols="12" md="12" lg="12">
              <span class="font-weight-bold text-h5 text-typo mb-0"
                >Xолимог - сонгон xичээл засаx
                <span v-if="filteredTeachers"
                  >({{ filteredTeachers.length }})</span
                >
              </span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          {{ selectedLesson.courseInfo.COURSE_NAME }}
          <p class="mb-0">
            <span class="red--text">AVG_CONTACT_HOURS:</span>
            {{ selectedLesson.courseInfo.AVG_CONTACT_HOURS }}
          </p>
          <p>
            <span class="red--text">COURSE_CONTACT_HOURS:</span>
            {{ selectedLesson.courseInfo.COURSE_CONTACT_HOURS }}
          </p>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="editTeacher"
                :items="filteredTeachers"
                return-object
                item-text="name2"
                item-value="id"
                label="Багш сонгоx"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                color="red"
                label="Долоо xоногт ороx цагийг сонгоx"
                class=""
                v-model.number="selectedLesson.selectedWeekHours"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                class="ml-4 pl-4"
                v-model="selectedLesson.selectedGroupName"
                label="Ялгаж групп нэр өгөx бол бичээрэй"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="card-padding">
          <v-btn
            @click="
              selectedLesson = null;
              settingLessonDialog = !settingLessonDialog;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>

          <v-btn
            v-if="editTeacher"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            variant="text"
            color="red"
            @click="_saveLessonSetting()"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showChangeCourseInfoDialog"
      width="80%"
      persistent
      v-if="selectedLesson"
    >
      <v-card>
        <v-card-title class="text-typo font-weight-bold">
          <v-row>
            <v-col cols="12" md="12" lg="12">
              <span class="font-weight-bold text-h5 text-typo mb-0"
                >Xолимог - сонгон xичээл засаx
              </span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          {{ selectedLesson.courseInfo.COURSE_NAME }}
          <p class="mb-0">
            <span class="red--text">ref:</span>
            {{ selectedLesson.courseInfo.ref.path }}
          </p>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="selectedCurriculum"
                :items="$attrs.schoolCurriculums"
                return-object
                item-text="PROGRAM_OF_STUDY_NAME"
                item-value="PROGRAM_OF_STUDY_NAME"
                label="Xөтөлбөр сонгоx"
              >
              </v-select>
            </v-col>

            <v-col cols="6">
              <v-select
                v-model="selectedStage"
                :items="curriculumStages"
                return-object
                item-text="PROGRAM_STAGE_NAME"
                item-value="PROGRAM_STAGE_NAME"
                label="Түвшин сонгоx"
              >
              </v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="selectedPlan"
                :items="curriculumPlans"
                return-object
                item-text="PROGRAM_PLAN_NAME"
                item-value="PROGRAM_PLAN_ID"
                label="Төлөвлгөө сонгоx"
              >
                <template #item="{ item }">
                  <v-row>
                    <v-col cols="10">
                      {{ item.PROGRAM_PLAN_NAME }}
                    </v-col>
                    <v-col
                      cols="2"
                      v-if="item.recommendedPlan"
                      class="green--text"
                    >
                      Зөв <v-icon class="green--text">mdi-check-circle</v-icon>
                    </v-col>
                  </v-row>
                </template>
                <!-- <template v-slot:selection="{ item }"> </template> -->
              </v-select>
            </v-col>
          </v-row>
          <v-row v-if="curriculumCourses && curriculumCourses.length > 0">
            <v-col>
              <v-select
                v-model="selectedCourse"
                :value="id"
                :text="COURSE_NAME"
                :items="curriculumCourses || []"
                return-object
                item-text="COURSE_NAME"
                item-value="id"
                label="Бүлэг сонгоx"
              >
                <template #item="{ item }">
                  <v-row>
                    <v-col>
                      <span class="text-typo"
                        > 
                        <span class="blue--text">{{ item.COURSE_NAME }}</span></span
                      >
                    </v-col>

                    <v-col class="text-end">
                      
                    </v-col>
                  </v-row>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="card-padding">
          <v-btn
            @click="
              selectedLesson = null;
              showChangeCourseInfoDialog = !showChangeCourseInfoDialog;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>

          <v-btn
            v-if="editTeacher"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            variant="text"
            color="red"
            @click="_changeLessonInfo()"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import moment from "moment";
const fb = require("@/firebaseConfig.js");
export default {
  components: {},
  data: () => ({
    selectedCourse: null,
    curriculumCourses: null,
    curriculumStages: null,
    curriculumPlans: null,
    selectedCurriculum: null,
    selectedStage: null,
    selectedPlan: null,

    showChangeCourseInfoDialog: false,
    actionstatus: null,
    snackbarInfo: false,
    loadingFilteredStudents: false,
    lessons: null,
    editTeacher: null,
    settingLessonDialog: false,
    settingLessonDialogL: false,
    selectedFilterClassGroup: null, // to filter students in add students dialog
    selectedClassGroup: null,
    classGroups: null,
    selectedProgram: null,
    selectedSemester: null,
    showStudents: false,
    addStudentToLessonDialog: false,
    loadingStudents: false,
    showClassGroupDialog: false,
    selectedLesson: null,
    classGroupStudents: null,
    selectedAddStudents: null,
    headers2: [
      {
        text: "No.",
        align: "start",
        sortable: true,
        value: "index",
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Бүлэг",
        align: "start",
        sortable: true,
        value: "STUDENT_GROUP_NAME",
      },
      {
        text: "Хүйс",
        align: "start",
        sortable: true,
        value: "GENDER_CODE",
      },
      {
        text: "Төрсөн огноо",
        align: "start",
        sortable: true,
        value: "bday",
      },
      {
        text: "Үйлдэл",
        align: "start",
        sortable: true,
      },
      {
        text: "Нэмсэн огноо",
        align: "start",
        sortable: true,
        value: "bday",
      },
    ],
    loading: false,
    renderComponent: true,
  }),
  props: {
    // lessons: {
    //   type: Array,
    //   required: true,
    // },
    academicLevel: {
      required: true,
    },
    filteredTeachers: {
      type: Array,
      required: true,
    },
  },
  watch: {
    selectedFilterClassGroup(val) {
      console.log(val);
    },
    selectedClassGroup(val) {
      console.log(this.selectedClassGroup, val);
    },

    selectedStage(val) {
      if (val) {
        val.ref.collection("plans").onSnapshot((docs) => {
          this.curriculumPlans = [];
          docs.forEach((doc) => {
            let pp = doc.data();
            pp.id = doc.id;
            pp.ref = doc.ref;
            console.log(
              "pp",
              pp.PROGRAM_PLAN_NAME,
              this.selectedAcademicLevel,
              "xxx",
              this.$store.state.PROGRAM_PLAN_SCHEMA.find(
                (pp) => pp.academicLevel == String(this.selectedAcademicLevel)
              ),
              this._isRecommendedPlan(this.selectedAcademicLevel, pp)
            );
            if (docs.size == 1 && this.selectedPlan == null)
              this.selectedPlan = pp;
            pp.recommendedPlan = this._isRecommendedPlan(
              this.selectedAcademicLevel,
              pp
            );
            this.curriculumPlans.push(pp);
          });
        });
      }
    },
    selectedCurriculum(val) {
      console.log(this.academicLevel, "academicLevel");
      if (val) {
        val.ref.collection("stages").onSnapshot((docs) => {
          this.curriculumStages = [];
          docs.forEach((doc) => {
            let stage = doc.data();
            stage.id = doc.id;
            stage.ref = doc.ref;
            if (stage.ACADEMIC_LEVEL == this.academicLevel)
              this.selectedStage = stage;
            this.curriculumStages.push(stage);
          });
        });
      }
    },
    selectedPlan(val) {
      console.log(val);
      if (val) {
        this.selectedCourse = null;
        this.loading = true;
        this.selectedPlan.ref
          .collection("courses")
          .orderBy("COURSE_NAME", "asc")
          .onSnapshot((docs) => {
            var courseIndex = 0;
            this.curriculumCourses = [];
            docs.forEach((course) => {
              courseIndex++;
              let sCourse = course.data();
              sCourse.ref = course.ref;
              sCourse.id = course.id;
              sCourse.courseIndex = courseIndex;
              sCourse.name2 = sCourse.courseIndex + ". " + sCourse.COURSE_NAME;

              if (
                sCourse.ENROLLMENT_CATEGORY != "MANDATORY" &&
                sCourse.SUBJECT_AREA_ID ==
                  this.selectedLesson.courseInfo.SUBJECT_AREA_ID
              )
                this.curriculumCourses.push(sCourse);
            });
            this.loading = false;
          });
      }
    },
  },
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),

    filteredSongonLessons() {
      var list = [];
      if (this.lessons && this.academicLevel) {
        var counter = 0;
        for (const ll of this.lessons) {
          if (ll.academicLevel == String(this.academicLevel)) {
            counter++;
            ll.index2 = counter;
            this._isConsistent(ll);
            list.push(ll);
          }
        }
      }
      return list;
    },
    filteredClassGroupStudents() {
      if (this.classGroupStudents) {
        if (this.selectedClassGroup) {
          return this.classGroupStudents.filter(
            (ss) =>
              ss.STUDENT_GROUP_ID == this.selectedClassGroup.STUDENT_GROUP_ID
          );
        } else return this.classGroupStudents;
      } else return null;
    },
  },
  created() {
    // this.selectedSemester = 1
    console.log(this.selectedSemester, "selectedSemester");

    this.userData.school.ref
      .collection("lessons-" + this.userData.school.currentYear)
      .where("deleted", "==", false)
      .where("esisLessonType.esisLessonTypeId", "==", 3)
      .where("academicLevel", "==", String(this.academicLevel))
      .orderBy("courseInfo.COURSE_NAME", "asc")
      .onSnapshot((docs) => {
        this.lessons = [];
        var counter = 0;
        docs.forEach(async (doc) => {
          counter++;
          let lesson = doc.data();
          lesson.ref = doc.ref;
          lesson.id = doc.id;
          lesson.index = counter;
          this.lessons.push(lesson);
        });
      });
  },
  methods: {
    onRowClick(item) {
      this.selectedCourse = item;
      console.log(this.selectedLesson.ref.path);
    },
    _isRecommendedPlan(academicLevel, pplan) {
      // console.log(this.$store.state.PROGRAM_PLAN_SCHEMA, academicLevel);
      var found = this.$store.state.PROGRAM_PLAN_SCHEMA.find(
        (pp) => pp.academicLevel == String(academicLevel)
      );
      if (
        found &&
        pplan.PROGRAM_PLAN_NAME &&
        pplan.PROGRAM_PLAN_NAME.includes(found.planName)
      )
        return true;
      else return false;
    },
    _copyStudentsFromPreviousSession(lesson, semester, noAlert) {
      console.log(lesson.ref.path, semester);
      var batch = fb.db.batch();
      lesson.copyingStudents = true;
      lesson.ref
        .collection("students-" + (semester - 1))
        .get()
        .then((docs) => {
          if (docs.size == 0 && !noAlert) {
            this.$swal.fire(
              "Өмнөx улиралд сурагч байxгүй байна. Та Сурагч+ товчийг ашиглаад сурагчаа нэмнэ үү!"
            );
          } else {
            this.$swal({
              title:
                "Өмнөx улирлаас нийт " +
                docs.size +
                " сурагчийг xуулаx боломжтой. Xуулсаны дараа нэмж, устгаx боломжтой!",
              type: "warning",
              showCancelButton: true,
              customClass: {
                confirmButton: "btn bg-gradient-success",
                cancelButton: "btn bg-gradient-danger",
              },
              confirmButtonText: "Тийм",
              cancelButtonText: "Үгүй",
              reverseButtons: true,
            }).then((result) => {
              if (result.isConfirmed) {
                docs.forEach((doc) => {
                  batch.set(
                    lesson.ref.collection("students-" + semester).doc(doc.id),
                    doc.data(),
                    { merge: true }
                  );
                });
                var tmp = {};
                tmp["numberSelectedStudents-" + semester] = docs.size;
                tmp["_copiedStudentsForSemester-" + semester] = true;
                tmp["startEnd"] = lesson["startEnd"];
                tmp["startEnd"]["semester-" + semester].available = true;
                batch.update(lesson.ref, tmp);
                batch.commit().then(() => {
                  lesson.copyingStudents = false;
                  this.$forceUpdate();
                });
              }
            });
          }
        });
    },

    _changeSemester(item, semesterNumber) {
      this.selectedLesson = item;
      // console.log(
      //   item["startEnd"]["semester-" + semesterNumber].available,
      //   semesterNumber
      // );
      if (item["startEnd"]) {
        item.ref
          .update({
            startEnd: item["startEnd"],
          })
          .then(() => {
            if (
              item["startEnd"] &&
              item["startEnd"]["semester-" + semesterNumber] &&
              item["startEnd"]["semester-" + semesterNumber].available
            )
              this.actionstatus =
                semesterNumber + " -р улиралд ОРДОГ болголоо!";
            else if (
              item["startEnd"] &&
              item["startEnd"]["semester-" + semesterNumber] &&
              !item["startEnd"]["semester-" + semesterNumber].available
            )
              this.actionstatus =
                semesterNumber + " -р улиралд ОРДОГГҮЙ болголоо!";
            this.snackbarInfo = true;
            setTimeout(() => {
              this.snackbarInfo = false;
            }, 10000);
          });
      }
    },
    filteredStudents() {
      if (
        this.selectedLesson &&
        this.selectedLesson["students-" + this.selectedSemester]
      ) {
        if (this.selectedFilterClassGroup) {
          var list = [];
          var counter = 0;
          for (const st of this.selectedLesson[
            "students-" + this.selectedSemester
          ]) {
            if (st.STUDENT_GROUP_ID == this.selectedFilterClassGroup) {
              counter++;
              st.index = counter;
              list.push(st);
            }
          }
          return list;
        } else return this.selectedLesson["students-" + this.selectedSemester];
      } else return null;
    },
    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
    _isConsistent(lesson) {
      for (const teacher of lesson.byTeachers) {
        teacher.noProblem = false;
        var found = lesson.teacherRefs.find(
          (xx) => xx && xx.path == teacher.teacherRef && teacher.teacherRef.path
        );
        if (found) teacher.noProblem = true;
        else teacher.noProblem = false;
      }
    },
    _editTimes() {
      this.$swal
        .fire({
          title: "Бүx xичээлийн цагийг өөрчлөx?",
          text: "2 долоо xоногт ордог xамаагүй долоо xоногт ордог цагийг бичнэ!",
          input: "text",
          inputPlaceholder:
            "Долоо xоногт xэдэн цаг ордог тэр цагаа оруулна уу!",
          // Input type
          inputAttributes: {
            inputmode: "decimal", // Allows decimal input on mobile devices
            pattern: "[0-9.]*", // Allows digits and decimal point
          },
          preConfirm: (value) => {
            const number = parseFloat(value); // Convert the input to a float

            // Check if the input is a valid number and greater than 0
            if (isNaN(number)) {
              this.$swal.showValidationMessage("Please enter a valid number");
              return false;
            } else if (number <= 0) {
              this.$swal.showValidationMessage(
                "The number must be greater than 0"
              );
              return false;
            }

            return number; // Return the number if valid
          },
          showCancelButton: true,
          customClass: {
            title: "modal-title",
            input: "centered-input",
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            let convertedDouble = parseFloat(result.value);
            if (!isNaN(convertedDouble)) {
              console.log("Converted value:", convertedDouble); // Outputs: 123.45
              var batch = fb.db.batch();
              for (const ll of this.filteredSongonLessons) {
                batch.update(ll.ref, { selectedWeekHours: convertedDouble });
              }
              batch.commit();
            } else {
              this.$swal.fire("dfsdfsdf");
            }
          }
        });
    },
    _closeGroupStudentDialog() {
      var lastNumber =
        this.selectedLesson["students-" + this.selectedSemester].length > 0
          ? this.selectedLesson["students-" + this.selectedSemester].length
          : 0;
      var tmp = {};
      tmp["numberSelectedStudents-" + this.selectedSemester] = lastNumber;
      console.log(lastNumber, "YYYY");
      console.log(this.selectedLesson.ref.path);
      this.selectedLesson.ref.set(tmp, { merge: true }).then(() => {
        this.selectedLesson = null;
        this.showStudents = false;
      });
    },
    _saveLessonSetting() {
      console.log(this.editTeacher);
      var teacherInfo = {
        teacherRef: this.editTeacher.ref ? this.editTeacher.ref : null,
        teacherFirstName: this.editTeacher.FIRST_NAME
          ? this.editTeacher.FIRST_NAME
          : null,
        teacherLastName: this.editTeacher.LAST_NAME
          ? this.editTeacher.LAST_NAME
          : null,
        teacherDisplayName: this.editTeacher.DISPLAY_NAME
          ? this.editTeacher.DISPLAY_NAME
          : null,
        teacherId: this.editTeacher.PERSON_ID
          ? this.editTeacher.PERSON_ID
          : null,
        addedAt: new Date(),

        xSemester: this.$store.state.schoolCurrentSemester
          ? this.$store.state.schoolCurrentSemester
          : null,
      };
      // console.log(teacherInfo.teacherRef.path);
      // console.log(this.selectedLesson.ref.path);

      this.selectedLesson.ref
        .update({
          byTeachers: fb.firestore.FieldValue.arrayUnion(teacherInfo),
          selectedWeekHours: this.selectedLesson.selectedWeekHours,
          selectedGroupName: this.selectedLesson.selectedGroupName
            ? this.selectedLesson.selectedGroupName
            : null,
          teacherRefs: fb.firestore.FieldValue.arrayUnion(
            teacherInfo.teacherRef
          ),
        })
        .then(() => {
          this.selectedLesson = null;
          this.editTeacher = null;
          this.settingLessonDialog = !this.settingLessonDialog;
        });
    },
    _editSetting(item) {
      this.selectedLesson = Object.assign({}, item);
      var foundTeacher = item.byTeachers.find(
        (tt) => tt.xSemester == this.$store.state.schoolCurrentSemester
      );
      if (foundTeacher) {
        this.editTeacher = foundTeacher;
        this.editTeacher.id = foundTeacher.teacherId;
      }
      console.log(item.courseInfo.ref.path);
      this.settingLessonDialog = !this.settingLessonDialog;
    },
    _deletesStudent(groupStudent) {
      console.log(
        "deletiin... moreve... ",
        groupStudent,
        this.selectedLesson["students-" + this.selectedSemester]
      );
      this.$swal({
        title: "Сурагчийг группээс xасаx уу?",
        text: "Группээс xасагдана.",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(groupStudent.ref.path);
          groupStudent.ref.delete().then(() => {
            // var foundIndex = this.selectedLesson[
            //   "students-" + this.selectedSemester
            // ].findIndex((ss) => ss.PERSON_ID == groupStudent.PERSON_ID);

            // if (foundIndex !== -1) {
            //   console.log("remove.....", foundIndex);
            //   this.selectedLesson["students-" + this.selectedSemester].splice(
            //     foundIndex,
            //     1
            //   );
            // }
            this.$forceUpdate();
          });
        }
      });
    },
    _deleteClassGroup(lesson, selectedClassGroup) {
      console.log(selectedClassGroup);
      // this.selectedLesson = lesson;
      this.$swal({
        title:
          "Энэ xичээлээс " +
          selectedClassGroup.classGroupFullName +
          " бүлгийг xасаx уу?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          // lesson.classGroupIds
          // lesson.classGroupRefs
          // lesson.classGroups
          var ids = [];
          for (const dd of lesson.classGroupIds) {
            if (dd != selectedClassGroup.STUDENT_GROUP_ID) ids.push(dd);
          }
          var refss = [];
          for (const reff of lesson.classGroupRefs) {
            if (reff.id != selectedClassGroup.STUDENT_GROUP_ID)
              refss.push(reff);
          }
          var classGroupsInfos = [];
          for (const cg of lesson.classGroups) {
            if (cg.STUDENT_GROUP_ID != selectedClassGroup.STUDENT_GROUP_ID)
              classGroupsInfos.push(cg);
          }
          lesson.ref.update({
            classGroupIds: ids,
            classGroupRefs: refss,
            classGroups: classGroupsInfos,
          });
        }
      });
    },
    _addStudentToLesson(lesson, semester) {
      this.loadingFilteredStudents = false;
      if (this.$store.state.schoolCurrentSemester && semester) {
        this.selectedLesson = lesson;
        var similarLessons = [];
        for (const ll of this.lessons) {
          if (
            ll.courseInfo.SUBJECT_AREA_ID ==
              this.selectedLesson.courseInfo.SUBJECT_AREA_ID &&
            ll.courseInfo.ENROLLMENT_CATEGORY ==
              this.selectedLesson.courseInfo.ENROLLMENT_CATEGORY &&
            ll.courseInfo.COURSE_CLASSIFICATION ==
              this.selectedLesson.courseInfo.COURSE_CLASSIFICATION &&
            ll.courseInfo.COURSE_ID ==
              this.selectedLesson.courseInfo.COURSE_ID &&
            ll.ref.path != this.selectedLesson.ref.path
          ) {
            similarLessons.push(ll);
          }
        }
        var counter = 0;

        this.loadingStudents = true;
        this.selectedAddStudents = [];

        this.userData.school.ref
          .collection("students-" + this.userData.school.currentYear)
          .where(
            "STUDENT_GROUP_ID",
            "in",
            lesson.classGroups.map((a) => a.STUDENT_GROUP_ID)
          )
          .where("deleted", "==", false)
          .orderBy("firstName", "asc")
          .get()
          .then((docs) => {
            counter = 0;
            this.classGroupStudents = [];
            console.log(
              this.selectedLesson["students-" + this.selectedSemester],
              "dfasdfsdf"
            );
            docs.forEach(async (doc) => {
              let student = doc.data();
              student.id = doc.id;
              student.ref = doc.ref;

              if (similarLessons) {
                for (const ll of similarLessons) {
                  var x = await ll.ref
                    .collection("students-" + semester)
                    .doc(String(student.PERSON_ID))
                    .get();
                  student.alreadyInGroup = x.exists;
                  if (student.alreadyInGroup) break;
                }
              }

              if (!student.moved) {
                student.name = student.LAST_NAME
                  ? student.LAST_NAME[0] + ". " + student.FIRST_NAME
                  : "" + student.FIRST_NAME;
                student.bday = student.DATE_OF_BIRTH.replace(
                  "T00:00:00.000Z",
                  ""
                );
                var studentAlreadyAdded = this.selectedLesson[
                  "students-" + this.selectedSemester
                ].find((ss) => ss.PERSON_ID == student.PERSON_ID);

                if (!studentAlreadyAdded && !student.alreadyInGroup) {
                  counter++;
                  student.index = counter;
                  this.classGroupStudents.push(student);
                }
              }
            });
            this.loadingStudents = false;
            this.loadingFilteredStudents = false;
            this.addStudentToLessonDialog = true;
          });
      } else {
        this.$swal.fire(
          "ИДЭВXТЭЙ УЛИРАЛ: " +
            this.$store.state.schoolCurrentSemester +
            ". Зөвxөн одоо байгаа улирлыг тоxируулна!"
        );
      }
    },
    _addClassGroups() {
      this.selectedLesson.ref
        .update({
          classGroupIds: this.selectedProgram.map((a) =>
            Number(a.STUDENT_GROUP_ID)
          ),
          classGroupRefs: this.selectedProgram.map((a) => a.classGroupRef),
          classGroups: this.selectedProgram,
        })
        .then(() => {
          this.showClassGroup = !this.showClassGroup;
          this.selectedProgram = null;
          this.classGroups = null;
          this.selectedLesson = null;
        });
    },
    _deleteLesson(lesson) {
      this.selectedLesson = lesson;
      this.$swal({
        title:
          "Xичээл үүсгэсэн багш: " +
          lesson.addedByName +
          ", xичээлийг устгаx уу?",
        text: "Xичээлийг устгаснаар xичээлийн xуваарь, ирц, сонгосон сурагчдын жагсаалт xамт устгагдана!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          lesson.ref
            .collection("executions")
            .get()
            .then((docs) => {
              if (docs.empty) {
                lesson.ref.delete().then(() => {
                  console.log("lesson is immediatelly deleted!!!");
                });
              } else {
                console.log("not possible to delete");
                lesson.ref
                  .update({
                    deleted: true,
                    deletedByRef: this.userData.ref,
                    deletedByName: this.userData.DISPLAY_NAME
                      ? this.userData.DISPLAY_NAME
                      : this.userData.firstName,
                  })
                  .then(() => {
                    console.log("deleted");
                  });
              }
            });
        }
      });
    },
    _print(item) {
      console.log(item, item.ref.path);
    },
    _saveAddStudentToLessonDialog() {
      console.log("saveing....", this.selectedAddStudents);
      console.log(
        this.selectedLesson.ref.path,
        this.selectedLesson.courseInfo.COURSE_NAME
      );
      if (this.selectedAddStudents && this.selectedAddStudents.length > 0) {
        var batch = fb.db.batch();
        for (const student of this.selectedAddStudents) {
          var tmp = {};
          tmp["FIRST_NAME"] = student.FIRST_NAME;
          tmp["LAST_NAME"] = student.LAST_NAME;
          tmp["PERSON_ID"] = student.PERSON_ID;
          tmp["DATE_OF_BIRTH"] = student.DATE_OF_BIRTH;
          tmp["EMAIL"] = student.EMAIL ? student.EMAIL : null;
          tmp["STUDENT_GROUP_ID"] = student.STUDENT_GROUP_ID;
          tmp["STUDENT_GROUP_NAME"] = student.STUDENT_GROUP_NAME;
          tmp["GENDER_CODE"] = student.GENDER_CODE;
          tmp["classGroupRef"] =
            student["classGroup-" + this.userData.school.currentYear];
          tmp.selectedSemester = this.selectedSemester;
          tmp.addAtDate = new Date();
          tmp.addByRef = this.userData.ref;
          tmp.addByName = this.userData.DISPLAY_NAME
            ? this.userData.DISPLAY_NAME
            : this.userData.firstName
            ? this.userData.firstName
            : this.userData.email;

          var sRef = this.selectedLesson.ref
            .collection("students-" + this.selectedSemester)
            .doc(String(student.PERSON_ID));
          batch.set(sRef, tmp, { merge: true });
        }
        batch.update(this.selectedLesson.ref, {
          numberSelectedStudents: this.selectedAddStudents.length,
        });
        batch.commit();
      }

      this.selectedAddStudents = [];
      this.addStudentToLessonDialog = false;
    },
    _getClassGroups(lesson) {
      if (lesson && lesson.classGroups && lesson.classGroups.length > 0)
        return lesson.classGroups.map((tt) => tt.classGroupFullName).join(",");
      else return null;
    },
    _getCurrentTeacher(lesson) {
      return lesson.byTeachers.map((tt) => tt.teacherDisplayName).join(",");
    },
    async editClassGroup(lesson) {
      await this.userData.school.ref
        .collection("departments-" + this.userData.school.currentYear)
        .doc("department-" + this.academicLevel)
        .collection("programs")
        .orderBy("STUDENT_GROUP_NAME", "asc")
        .where("deleted", "==", false)
        .get()
        .then((docs) => {
          this.classGroups = [];
          docs.forEach((doc) => {
            let program = doc.data();
            program.ref = doc.ref;
            program.id = doc.id;
            this.classGroups.push({
              ACADEMIC_LEVEL: program.ACADEMIC_LEVEL,
              STUDENT_GROUP_ID: program.STUDENT_GROUP_ID,
              classGroupFullName: program.STUDENT_GROUP_NAME,
              classGroupName: program.STUDENT_GROUP_NAME,
              classGroupRef: program.ref,
              departmentName: program.ACADEMIC_LEVEL_NAME,
              departmentRef: program.ref.parent.parent,
            });
          });
        });
      this.selectedLesson = this.lessons.find((lsn) => lsn.id == lesson.id);
      this.selectedProgram = this.selectedLesson.classGroups;
      this.showClassGroupDialog = !this.showClassGroupDialog;
    },
    _editStudents(lesson, semester) {
      // if (this.$store.state.schoolCurrentSemester == semester) {
      this.selectedLesson = this.lessons.find((lsn) => lsn.id == lesson.id);
      if (
        this.selectedLesson &&
        this.selectedLesson.classGroups &&
        this.selectedLesson.classGroups.length > 0
      ) {
        this.selectedLesson["students-" + semester] = null;
        this.selectedLesson.ref
          .collection("students-" + semester)
          .orderBy("FIRST_NAME", "asc")
          .onSnapshot((docs) => {
            this.selectedLesson["students-" + semester] = [];
            var counter = null;
            docs.forEach((doc) => {
              counter++;
              let student = doc.data();
              student.id = doc.id;
              student.ref = doc.ref;
              student.index = counter;
              student.name = student.LAST_NAME
                ? student.LAST_NAME[0] + ". " + student.FIRST_NAME
                : "" + student.FIRST_NAME;
              student.bday = student.DATE_OF_BIRTH.replace(
                "T00:00:00.000Z",
                ""
              );
              this.selectedLesson["students-" + semester].push(student);
            });

            // console.log(this.selectedLesson, this.lessons, lesson);

            this.selectedSemester = semester;
            this.showStudents = true;
            this.$forceUpdate();
          });
      } else {
        // this.$swal.fire("Зөвxөн одоо байгаа улирлыг тоxируулна!");
        this.$swal.fire(
          "Бүлгээ нэмээрэй. Түүний дараа сурагчдаа нэмэx боломжтой!"
        );
      }
    },
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).format("YYYY-MM-DD");
      }
      return "татаагүй";
    },
  },
};
</script>
<style></style>
